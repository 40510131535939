@media print {
  @page {
    size: portrait;
  }

  body * {
    visibility: hidden;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }

  .section-to-print {
    position: absolute;
    padding: 35px;
    left: 0;
    top: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}